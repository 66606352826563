import React, { useMemo, useState, useEffect } from "react";
import { graphql } from "gatsby";
import { get } from "src/utils";
import { getCommonHeroImages } from "src/utils"
import createRelatedResources from "src/components/helpers/createRelatedResources";
import getContentConnectorFields, {
  getContentConnectorAnchors,
} from "src/components/helpers/getContentConnectorFields";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import getBannerData from "src/components/helpers/getBannerData";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import findSiteType from "src/components/helpers/findSiteType";
import getSchemaMarkup from "src/components/helpers/getSchemaMarkup"
import { CommonHero } from "src/components/commonComponents/CommonHeroTemplate/CommonHero.js";
import { ContentConnector } from 'src/components/commonComponents/contentConnector.js';
import loadable from '@loadable/component';
import { SEO, SecondaryNavbar as ScrollLinks, Banner, ReviewIframeSection } from "src/components/commonComponents";
import { getPageDataJsonPath } from "src/utils";
import { useLazyLoadOnScroll } from 'src/hooks/useLazyLoadOnScroll';
import getQaData from "src/components/helpers/getQaData";
const DemoFormWithBg = loadable(() => import('src/components/commonComponents/forms/DemoFormWithBg'))
const Resources = loadable(() => import('src/components/solutions/Resources'))
const DemoForm = loadable(() => import('src/components/solutions/DemoForm'))
const FooterCta = loadable(() => import('src/components/services/footerCta'))
const QAList = loadable(() => import("src/components/QAList"));
const QuoteBlock = loadable(() => import('src/components/commonComponents/quoteBlock'), { resolveComponent: (components) => components.QuoteBlock });
const QuickLinks = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.QuickLinks
});
const Helmet = loadable(() => import("react-helmet"));
const getImageObject = require("src/../helpers/getImageObject")
const ProductTemplate = ({ location, pageContext, path, data }) => {
  const [poupLoad, setPopupLoad] = useState(false)
  const locale = pageContext.locale;
  const relatedResources = createRelatedResources(data?.allSitePage?.nodes, {
    hasDescription: true,
  });
  const fieldMarketoFormTitleId = get(pageContext, "relationships.fieldRefMarketoForm.title");
  const shouldLoad = useLazyLoadOnScroll();
  let contentConnectors = [];
  if (Array.isArray(pageContext?.relationships?.fieldContentConnector)) {
    contentConnectors = getContentConnectorFields(
      pageContext.relationships?.fieldContentConnector,
      "productPage",
      locale
    );
  }
  const anchors = getContentConnectorAnchors(contentConnectors)?.map(c => ({ ...c, id: c.id }));
  const formData = pageContext.relationships?.fieldRefMarketoForm;
  const { id, serial, gaLabel } = extractMarketoValues(
    formData?.fieldEmbedCode
  );
  const heroImage = pageContext?.commonHeroProps?.heroImage

  /**
   * @Schema_Markup
   */
  const schemaMarkup = getSchemaMarkup(pageContext?.fieldSchemaMarkup)
  const schemaMarkupVideo = getSchemaMarkup(pageContext?.fieldVideoSchemaMarkup)

  // Banner Section
  const bannerData = getBannerData(pageContext?.relationships?.fieldReportBannerSection);

  /**
 * @Open_Graph_Markup
 * Some of the data inside the objects will be done inside SEO component
 */
  const OGMarkup = getOGMarkup(pageContext?.fieldPageTitle, 'product', { gatsbyImage: heroImage?.shareImage });
  const { entityMetatags: metaTags } = pageContext;
  // url - set inside SEO
  // description - set inside SEO
  // Locale - left as default: en_US
  // type - left as default: Webpage

  const genericCtaEntity = pageContext?.relationships?.fieldGenericCta;
  const noContentBelowContentConnector = !genericCtaEntity && !pageContext.fieldAddRelated
  const siteClass = findSiteType('mc') ? "new-product-page-mc tw-new-product-page-mc" : findSiteType('ems') ? "new-product-page-ems tw-new-product-page-ems" : ""
  const fieldBlockQuoteSection = pageContext?.relationships?.fieldBlockQuoteSection
  const fieldReviewsSection = pageContext?.relationships?.fieldReviewsSection?.fieldReviewIframe
  const fieldQaListSection = pageContext?.relationships?.fieldQaListSection
  const questionAnswersData = getQaData(fieldQaListSection);
  const quickLinks = pageContext?.relationships?.field_cta_block?.relationships?.fieldCtaS
  const quickLinksTitle = pageContext?.relationships?.fieldQuickLinks?.title;
 
  const quoteData = {
    quote: fieldBlockQuoteSection?.fieldQuoteBody,
    quoteAuthor: fieldBlockQuoteSection?.title,
    quoteAuthorDesignation: fieldBlockQuoteSection?.fieldCustomerDesignation,
    quoteAuthorCompany: fieldBlockQuoteSection?.company,
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlHash = window.location.hash
    const gatsbyInternalAnchor = urlParams.get('anchor');
    if (gatsbyInternalAnchor && !urlHash) {
      setTimeout(() => {
        if (document) {
          const elementToScroll = document.getElementById(gatsbyInternalAnchor)
          const offset = elementToScroll?.getBoundingClientRect();
          if (!elementToScroll || !offset) {
            return
          }
          window.scrollTo({
            behavior: elementToScroll ? "smooth" : "auto",
            top: elementToScroll ? offset.top : 0
          });
        }
      }, 500);
    }
  }, []);

  useEffect(() => {
    const urlHash = window.location.hash;
    const reloadCount = sessionStorage.getItem('reloadCount') || 1;
    if (urlHash && reloadCount < 2) {
      sessionStorage.setItem('reloadCount', reloadCount + 1);
      setTimeout(() => {
        if (document) {
          if (urlHash) {
            const elementToScroll = document.getElementById(urlHash.replace('#', ''));
            elementToScroll.scrollIntoView();
          }
        }
      }, 1300);

    }
  }, []);

  let hreflangAlternatives = []

  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });

  hreflangAlternatives?.find(trans => {
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if (hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
  })

  const search = location.search;
  hreflangAlternatives = search.length > 0 ? [] : hreflangAlternatives
  const pageDataPath = getPageDataJsonPath(pageContext?.entityUrl?.path)
  const preloadImages = useMemo(() => getCommonHeroImages(pageContext?.commonHeroProps), [pageContext?.commonHeroProps])
  return (
    <>
      <div pagetype="taxonomy" className={"new-product-page tw-new-product-page" + siteClass} tid={pageContext?.entityId}>
        <SEO
          lang={locale}
          pageUrl={pageContext?.entityUrl?.path}
          title={pageContext?.fieldPageTitle}
          meta={metaTags}
          schemaMarkup={schemaMarkup}
          schemaMarkupVideo={schemaMarkupVideo}
          OGMarkup={OGMarkup}
          alternatives={hreflangAlternatives}
          preloads={[pageDataPath]}
          preloadedImages={preloadImages}
        />
        {!pageContext.fieldHideHero && (
          <CommonHero
            commonHeroProps={pageContext?.commonHeroProps}
            contentProp={"Product"}
            onPopupLoad={setPopupLoad}
          />
        )}
        {(bannerData?.fieldShowReportBanner && bannerData?.status) && (
          <div className="container">
            <Banner
              {...bannerData}
            />
          </div>
        )}
        {quoteData?.quote && <div className="container tw-flex tw-items-center tw-justify-center !tw-my-[52px] md:!tw-my-[76px] tw-flex-col md:tw-flex-row tw-gap-[50px] md:tw-gap-[160px] tw-px-4 md:tw-px-0">
          <div className="tw-w-full md:tw-w-1/2">
            <QuoteBlock
              data={quoteData}
              isCompany
              isNarrow
              hasTextBig
              hasAuthorLeft
              hasBackgroundTransparent
            />
          </div>
          <div className="tw-w-full md:tw-w-1/2">
            {!!fieldReviewsSection?.length && <ReviewIframeSection fieldReviewsSection={fieldReviewsSection} />}
          </div>
        </div>}

        {!!anchors.length &&
          <ScrollLinks
            title={pageContext.fieldTermJumpLinksHeader}
            anchors={anchors}
          />
        }
        <div className={"container content-visibility-auto-mobile " + (noContentBelowContentConnector ? "!tw-mb-[76px]" : "")}>
          {/* +++ DESCRIPTION +++ */}
          {pageContext.description && !!pageContext.description.value && (
            <section className="section">
              <div className="columns">
                <div
                  className="column is-10 is-offset-1-tablet has-text-centered dangerously-html-handle"
                  dangerouslySetInnerHTML={{
                    __html: pageContext.description.value,
                  }}
                />
              </div>
            </section>
          )}
          {contentConnectors.map((data, index) => {
            return (

              <div key={index} className="section-container" style={{ position: 'relative' }}>
                <ContentConnector
                  key={index}
                  data={data}
                  entityId={pageContext.uuid}
                  pageType={pageContext.type?.id}
                  locale={locale}
                  path={path}
                  pageTitleForMarketo={pageContext?.commonHeroProps?.heading}
                />
              </div>
            )
          })}
          {pageContext.fieldAddRelated &&
            relatedResources &&
            relatedResources.length !== 0 && (
              <section className={`section-container ${!(genericCtaEntity?.status) ? "mb-5" : ""}`}>
                <div id="resources" className="jumplinks-anchor tw-block tw-relative -tw-top-[132px] tw-invisible"></div>
                <h2 className="title title-3">
                  #Resources
                </h2>
                <Resources
                  title=""
                  isCarousel={false}
                  data={relatedResources}
                />
              </section>
            )}
          {/*  MARKETO FORM  */}
          {formData?.fieldVersions !== "advanced" ? <section className={"section taxonomy-form-wrapper full-width-bg-wrapper"}>
            <DemoForm
              fieldFormHeader={formData?.fieldFormHeader}
              description={formData?.fieldFormText?.value}
              image={getImageObject(formData?.relationships?.fieldFormImage, formData?.fieldFormImage)}
              fieldMarketoFormTitleId={fieldMarketoFormTitleId}
              serial={serial}
              formId={id}
              gaLabel={gaLabel}
              shouldLoad={(shouldLoad || poupLoad)}
            />
          </section>
            :
            <section className="section taxonomy-form-wrapper tw-demo-form-bg">
              {<DemoFormWithBg
                fieldFormHeader={formData?.fieldFormHeader}
                description={formData?.fieldFormText?.value}
                image={getImageObject(formData?.relationships?.fieldFormImageWithCover, formData?.fieldFormImage)}
                bgImage={getImageObject(formData?.relationships?.fieldMediaImage?.relationships?.field_image, formData?.relationships?.fieldMediaImage?.fieldImage)}
                fieldMarketoFormTitleId={fieldMarketoFormTitleId}
                serial={serial}
                formId={id}
                gaLabel={gaLabel}
                pageTitle={pageContext?.commonHeroProps?.heading}
                cta={formData?.fieldCtaUrl}
                shouldLoad={(shouldLoad)}
              />}
            </section>
          }

          {fieldQaListSection && <div className="section-container" >
            {fieldQaListSection?.fieldQaSchema ? (
              <Helmet>
                <script type="application/ld+json">{`
                ${fieldQaListSection?.fieldQaSchema}
              `}</script>
              </Helmet>
            ) : (
              ""
            )}
            <QAList
              contentEntity={fieldQaListSection}
              data={questionAnswersData}
            />

          </div>}
          {!!genericCtaEntity?.status &&
            <div className="section-container" component="FooterCta">
              <section className="section">
                <div className="footerCta-section tw-mb-[76px] has-word-break has-text-centered">
                  <FooterCta entity={genericCtaEntity} />
                </div>
              </section>
            </div>
          }
          {!!quickLinks?.length &&
            <div className="section-container tw-mb-[52px] md:tw-mb-[76px]">
              <QuickLinks fieldCtaBlock={pageContext?.relationships?.field_cta_block} quickLinks={quickLinks} title={quickLinksTitle} locale={locale} />
            </div>
          }

        </div>
      </div>
    </>
  );
};

export default ProductTemplate;

export const query = graphql`
query productResourcesById($pageId: String, $locale: String) {
  allSitePage(
    limit: 3
    filter: {componentChunkName: {eq: "component---src-templates-common-resources-template-js"}, context: {productIds: {elemMatch: {productId: {eq: $pageId}}}, locale: {eq: $locale}}}
  ) {
    nodes {
      ...resourceCards
    }
  }
}
`;